<template>
  <b-card class="emission-card-border border-secondary">
    <b-row class="mb-2">
      <b-col cols="6" class="d-flex justify-content-start align-items-center">
        <b-img :src="emissaoImage" class="mr-1" />
        <div class="d-flex flex-column justify-content-start">
          <span class="mb-25"> Detalhes da emissão </span>
          <div class="d-flex">
            <h2 class="mb-0 font-weight-bolder mr-1">
              {{ emissao.tipo_emissao }} - {{ emissao.nome_fantasia }}
            </h2>
            <b-img src="@/assets/custom-icons/coroa.svg" height="28" />
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="d-flex flex-column justify-content-end align-items-end">
        <h4 class="d-flex align-items-end">
          Status:
          <b-badge
            :class="`ml-1 badge badge-light-${emissao.is_ativo ? 'success' : 'danger'} border-${
              emissao.is_ativo ? 'success' : 'danger'
            }`"
          >
            <span class="text-dark">
              {{ emissao.is_ativo ? 'Ativo' : 'Inativo' }}
            </span>
          </b-badge>
        </h4>
        <h4 class="mb-0 d-flex align-items-end">
          Valor da emissão:
          <h2 class="mb-0 ml-1 font-weight-bolder">
            {{ emissao.valor_global_emissao | moneyFormat }}
          </h2>
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="7">
        <b-card class="emission-card-border emission-card-bg">
          <div class="d-flex justify-content-between">
            <span class="mb-75">Data da Emissão</span>
            <strong>{{ emissao.data_emissao | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Data do Vencimento</span>
            <strong>{{ emissao.data_vencimento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>Volume da série</span>
            <strong>{{ emissao.valor_global_emissao | moneyFormat }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <span class="mb-75">Emissão</span>
            <strong>{{ emissao.numero_emissao || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Série</span>
            <strong>{{ emissao.numero_serie || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Emissor</span>
            <strong>{{ emissao.emissor || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>Agente Fiduciário</span>
            <strong>{{ emissao.agente_fiduciario || '-' }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <span class="mb-75">Código IF</span>
            <strong>{{ emissao.codigo_cetip || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>ISIN</span>
            <strong>{{ emissao.codigo_isin || '-' }}</strong>
          </div>
        </b-card>
      </b-col>
      <b-col cols="5">
        <b-card class="emission-card-border emission-card-bg">
          <div class="d-flex justify-content-between">
            <span class="mb-75">
              Juros pagos
              {{
                emissao.data_ultimo_pagamento
                  ? `(${formatDate(emissao.data_ultimo_pagamento)})`
                  : ''
              }}
            </span>
            <strong>{{ emissao.juros_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Amortização</span>
            <strong>{{ emissao.amort_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Amortização Extraordinária</span>
            <strong>{{ emissao.amex_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Duration</span>
            <strong>{{ emissao.desc_duracao_remanescente || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Período de Pagamentos de Juros</span>
            <strong>{{ emissao.periodo_pagamento_juros }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>Período de Amortizações</span>
            <strong>{{ emissao.periodo_pagamento_amort || '-' }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between" v-if="emissao.data_carencia == null">
            <span class="mb-75">Liquidação do último pagamento</span>
            <strong>{{ emissao.data_ultimo_pagamento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between" v-if="emissao.data_carencia == null">
            <span>Próximo pagamento</span>
            <strong>{{ emissao.data_proximo_pagamento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between" v-if="emissao.data_carencia != null">
            <span>Carência</span>
            <strong>até {{ emissao.data_carencia | formatDate }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <span>Remuneração</span>
            <strong>{{ emissao.remuneracao || '-' }}</strong>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="emissao.data_carencia == null">
      <b-col cols="12">
        <b-card class="emission-card-border">
          <h4 class="font-weight-bolder">Histórico de pagamentos</h4>
          <vue-apex-charts
            ref="paymentHistory"
            height="200"
            :options="chartOptions"
            :series="series"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card class="emission-card-border">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder">PUs Históricos</h4>
              <span>Selecione o período para baixar.</span>
            </div>
            <div class="d-flex text-nowrap">
              <div class="input-icon">
                <flat-pickr
                  v-model="initialDate"
                  class="form-control"
                  :config="{ dateFormat: 'd/m/Y', maxDate: 'today' }"
                />
                <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
              </div>
              <div class="input-icon ml-1">
                <flat-pickr
                  v-model="finalDate"
                  class="form-control"
                  :config="{ dateFormat: 'd/m/Y', maxDate: 'today' }"
                />
                <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
              </div>
              <b-button variant="outline-warning" class="ml-1" @click="downloadExcel">
                <span class="text-secondary"> Baixar excel </span>
              </b-button>
              <b-button variant="outline-warning" class="ml-1" @click="openPusGraphModal">
                <span class="text-secondary"> Abrir gráfico </span>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-for="(doc, index) in docs" :key="index">
      <b-col cols="12">
        <b-card-actions
          v-if="doc.documentos.length"
          class="mt-2 emission-card-border"
          :title="doc.tipo"
          action-collapse
          :collapsed="index !== 0"
        >
        <b-input-group class="input-group-merge mr-2 mw-100 w-25 mb-2" v-if="doc.tipo === 'Termo de Cessão Fiduciária'">
          <b-form-input placeholder="Buscar por nome do arquivo" v-model="searchTermos" />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
          <div class="border" v-if="doc.documentos.length">
            <b-table
              v-if="doc.tipo !== 'Termo de Cessão Fiduciária'"
              responsive="sm"
              :items="doc.documentos"
              :fields="docFields"
              class="mb-0 pus-table"
            >
              <template #cell(url)="data">
                <a :href="data.item.url" target="_blank">
                  <feather-icon icon="DownloadIcon" size="22" class="text-success" />
                </a>
              </template>
            </b-table>
            <b-table
              responsive="sm"
              :items="termosDeCessao"
              :fields="docFields"
              class="mb-0 pus-table"
              ref="tableTermos"
              v-else
            >
              <template #cell(url)="data">
                <a :href="data.item.url" target="_blank">
                  <feather-icon icon="DownloadIcon" size="22" class="text-success" />
                </a>
              </template>
            </b-table>
            <div class="align-items-center mt-1 left-auto float-right mb-1 d-flex" v-if="doc.tipo === 'Termo de Cessão Fiduciária'">
              <v-select class="mr-3" v-model="perPageTermos" :options="perPageOptionsTermos" :clearable="false" />
              <span v-show="items.length" class="mr-3 mb-0 h5 mr-2"> {{ showingTermos }} </span>
              <b-pagination
                v-model="currentPageTermos"
                :total-rows="totalRowsTermos"
                :per-page="1"
                first-number
                last-number
                class="mb-0"
                @change="changePageTermo"
                ref="paginationTermo"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
          <div class="border-top pt-2" v-else>
            <span class="text-center">
              <feather-icon icon="AlertCircleIcon" size="20" class="text-muted mr-50" />
              Não há documentos disponíveis no momento.
            </span>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card-actions class="mt-2 emission-card-border" :title="'Obrigações'" action-collapse>
          <div class="d-flex">
            <b-form-group class="mr-2 mw-100 w-25 mb-2 mr-2" label="Nome do Evento" label-for="search">
              <b-input-group class="input-group-merge">
                <b-form-input placeholder="Buscar por nome do evento" v-model="search" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group class="mr-2 mw-100 w-25 mb-2 mr-2" label="Status" label-for="statusObrigacoesOpcoes">
              <v-select class="status-obrigacoes" placeholder="Status" v-model="statusObrigacoes" :options="statusObrigacoesOpcoes" :clearable="true" />
            </b-form-group>
            <b-form-group class="mr-2 mw-100 w-25 mb-2"  label="Data de Vencimento" label-for="obrigacoesVencimento">
              <div class="input-icon">
                <flat-pickr
                  v-model="obrigacoesVencimento"
                  class="form-control date-picker"
                  :config="{ dateFormat: 'd/m/Y', mode: 'range'}"
                  placaeholder="Data de vencimento"
                />
                <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
              </div>
            </b-form-group>
          </div>
          <div class="border" v-if="eventos?.length > 0">
            <b-table
              responsive="sm"
              :items="eventos"
              :fields="eventosFields"
              class="mb-0 pus-table"
              ref="table"
            >
              <template #cell(status)="data">
                <b-badge
                  :variant="`light-${variantBagdeStatusItemEventos(data.item.status)}`"
                  :class="`border-${variantBagdeStatusItemEventos(data.item.status)}`"
                  pill
                >
                  {{ variantNameStatusItemEventos(data.item.status) }}
                </b-badge>
              </template>

              <template #cell(observacao_instancia)="data">
                {{ data.item.observacao_instancia }}
              </template>

              <template #cell(url)="data">
                <a v-if="data.item?.link_anexo" :href="data.item.link_anexo" target="_blank">
                  <feather-icon icon="DownloadIcon" size="22" class="text-success" />
                </a>
                <span :id="`span-${data.item.id}`" v-else class="text-center">
                  <feather-icon icon="AlertCircleIcon" size="20" class="mr-50" />
                  <b-tooltip
                    :ref="`tooltip-${data.item.id}`"
                    triggers="hover"
                    :target="`span-${data.item.id}`"
                    title="Este evento não possui link para download."
                    variant="primary"
                    :delay="{ show: 100, hide: 10 }"
                  />
                </span>
              </template>
            </b-table>
          </div>
          <div class="border-top pt-2" v-else>
            <span class="text-center">
              <feather-icon icon="AlertCircleIcon" size="20" class="text-muted mr-50" />
              Não há obrigações cadastradas no momento.
            </span>
          </div>
          <div class="align-items-center mt-1 left-auto float-right mb-1 d-flex" v-if="eventos?.length > 0">
            <v-select class="mr-3" v-model="perPage" :options="perPageOptions" :clearable="false" />
            <span v-show="items.length" class="mr-3 mb-0 h5 mr-2"> {{ showing }} </span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="1"
              first-number
              last-number
              class="mb-0"
              @change="changePage"
              ref="pagination"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>

    <notes-and-receipts v-if="emissao.despesas" :expenses="emissao.despesas" class="mt-2 mb-0" />

    <b-modal id="pusModal" centered hide-footer header-bg-variant="white" size="lg">
      <span>Características</span>
      <h4 class="font-weight-bolder">PUs Históricos</h4>
      <span>{{ emissao.codigo_isin }} / {{ emissao.nome_emissao }} / {{ emissao.nome_serie }}</span>
      <vue-apex-charts
        ref="pus"
        height="400"
        :options="pusGraph.chartOptions"
        :series="pusGraph.series"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BTable,
  BBadge,
  BFormInput,
  BFormGroup,
  BButton,
  BButtonGroup,
  BTooltip,
  BPagination,
  BInputGroupAppend,
  BInputGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import http from '@/services/http'
import { BCardActions } from '@/@core/components/b-card-actions'
import VueApexCharts from 'vue-apexcharts'
import { moneyFormat } from '@/@core/comp-functions/data_visualization/datatable'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-cycle
import { formatDate } from '@core/utils/filter'
import { downloadFromResponse } from '@/@core/comp-functions/forms/cc-dropzone'
import NotesAndReceipts from '@/views/assignor/components/NotesAndReceipts.vue'
import Datatable from '@/views/common/crud/components/Datatable.vue'

export default {
  name: 'Emissoes',
  components: {
    BCardActions,
    BTable,
    BRow,
    BCol,
    BImg,
    BCard,
    BBadge,
    BFormGroup,
    BButton,
    BButtonGroup,
    BPagination,
    BTooltip,
    vSelect,
    flatPickr,
    BFormInput,
    VueApexCharts,
    NotesAndReceipts,
    BInputGroup,
    BInputGroupAppend,
  },
  filters: {
    moneyFormat,
    formatDate,
  },
  data() {
    return {
      selectedButton: 'price',
      pusGraph: {
        series: [],
        chartOptions: {
          chart: {
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Lato Black',
            markers: {
              radius: 12,
            },
          },
          grid: {
            show: true,
            row: {
              colors: ['transparent'],
            },
            column: {
              colors: ['transparent'],
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: {
              show: true,
              align: 'right',
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
              },
              offsetX: 0,
              offsetY: 0,
              rotate: 0,
              formatter: (value) => moneyFormat(value),
            },
          },
          tooltip: {
            shared: false,
          },
          colors: ['#3FE4A9'],
        },
      },
      items: [
        {
          data: '28/02/2023',
          arquivo: 'Demonstração do patrimônio separado 06/2022',
          download: 'Macdonald',
        },
      ],
      initialDate: '',
      finalDate: '',
      emissao: {},
      eventos: {},
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50],
      perPageOptionsTermos: [10, 25, 50],
      activePagination: true,
      statusObrigacoes: '',
      statusObrigacoesOpcoes: ['Completo', 'Atrasado', 'Pendente'],
      search: '',
      currentPageTermos: 1,
      perPageTermos: 10,
      searchTermos: '',
      totalRowsTermos: 1,
      totalItems: 0,
      totalItemsTermos: 0,
      series: [],
      termosDeCessao: [],
      obrigacoesVencimento: '',
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          markers: {
            radius: 12,
          },
        },
        grid: {
          show: true,
          row: {
            colors: ['transparent'],
          },
          column: {
            colors: ['transparent'],
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => moneyFormat(value),
          },
        },
        tooltip: {
          shared: false,
        },
        colors: ['#E3C092', '#C9794A', '#3FE4A9'],
      },
      docs: [],
      docFields: [
        {
          key: 'data_documento',
          label: 'Data',
          formatter: (value) => formatDate(value),
          tdClass: 'wd-10',
        },
        {
          key: 'name',
          label: 'Arquivo',
        },
        {
          key: 'url',
          label: 'Download',
          tdClass: 'wd-10',
        },
      ],
      eventosFields: [
        {
          key: 'resumo',
          label: 'Evento',
        },

        {
          key: 'data_vencimento',
          label: 'Vencimento',
          formatter: (value) => formatDate(value) || '-',
        },
        {
          key: 'data_baixa',
          label: 'Conclusão',
          formatter: (value) => formatDate(value) || '-',
        },
        {
          key: 'observacao_instancia',
          label: 'Observação',
        },
        {
          key: 'status',
          label: 'Status',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'url',
          label: 'Download',
        },
      ],
    }
  },
  async mounted() {
    const { data } = await this.$store.dispatch('assignor/getEmission', this.$route.params.serie_id)
    this.emissao = data.data
    await this.getEvents()
    await this.getDocs()
    await this.getTermos()
    await this.loadPaymentHistoryGraph()
  },
  methods: {
    async getTermos() {
      const termos = await http.get('cedente/termos_de_cessao', {
        params: { emissao_id: this.emissao.id, page: this.currentPageTermos, per_page: this.perPageTermos, search: this.searchTermos }
      })

      this.totalRowsTermos = termos.data.total_pages
      this.termosDeCessao = termos.data.data
      this.totalItemsTermos = termos.data.total_items
    },
    async getEvents() {
      const [initialDate, finalDate] = this.obrigacoesVencimento.split(' to ')

      const event = await http.get('cedente/eventos', {
        params: { 
          emissao_id: this.emissao.id, 
          page: this.currentPage, 
          per_page: this.perPage, 
          search: this.search, 
          status: this.statusObrigacoes,
          data_vencimento: (initialDate || null),
          data_vencimento_final: (finalDate || null)
        }
      })

      this.totalRows = event.data.total_pages
      this.eventos = event.data.data
      this.totalItems = event.data.total_items
    },
    async changePage() {
      if(this.$refs.pagination !== undefined) {
        this.currentPage = this.$refs.pagination.currentPage
      } else {
        this.currentPage = 1
      }
      await this.getEvents()
    },
    async changePageTermo() {
      await this.$refs.paginationTermo[0].value

      await this.getTermos()
    },
    async loadPaymentHistoryGraph() {
      const paymentHistory = await this.$store.dispatch(
        'assignor/paymentHistory',
        this.$route.params.serie_id,
      )
      this.series = paymentHistory.data.data.data
      this.chartOptions.xaxis.categories = paymentHistory.data.data.categories
      this.$refs.paymentHistory.updateOptions({ ...this.chartOptions })
    },
    formatDate,
    variantBagdeStatusItemEventos(status) {
      const variant = {
        Pendente: 'warning',
        Concluído: 'success',
        Atrasado: 'danger',
        Completo: 'success',
      }
      return variant[status]
    },
    variantNameStatusItemEventos(status) {
      const variant = {
        Pendente: 'A vencer',
        Concluído: 'Concluído',
        Atrasado: 'Atrasado',
        Completo: 'Completo',
      }
      return variant[status]
    },
    async getDocs() {
      const { data } = await this.$store.dispatch('assignor/getDocs', this.$route.params.serie_id)

      this.docs = data.data
    },
    selectButton(type) {
      this.result = ''
      this.selectedButton = type
    },
    async downloadExcel() {
      this.$swal({
        title: 'Carregando dados...',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
      })
      try {
        const { data } = await this.$store.dispatch('assignor/downloadPusHistory', {
          id: this.$route.params.serie_id,
          data_inicial: this.initialDate,
          data_final: this.finalDate,
        })
        downloadFromResponse(data, {
          mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileName: 'pus.xlsx',
        })
        this.$swal.close()
      } catch (error) {
        const reader = new FileReader()
        reader.onload = () => {
          const errorText = JSON.parse(reader.result).errors.join(' ')
          this.$swal.fire({
            title: 'Erro!',
            text: errorText,
            icon: 'error',
          })
        }
        reader.readAsText(error.response.data)
      }
    },
    async openPusGraphModal() {
      this.$bvModal.show('pusModal')
      this.$swal({
        title: 'Carregando dados...',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
      })

      const { data } = await this.$store.dispatch('assignor/pusGraph', {
        id: this.$route.params.serie_id,
        data_inicial: this.initialDate,
        data_final: this.finalDate,
      })
      this.$refs.pus.updateSeries([{ name: 'Valor', data: data.data.data }])
      this.$refs.pus.updateOptions({
        xaxis: {
          categories: data.data.categories,
        },
      })
      this.$swal.close()
    },
  },
  computed: {
    selectedButtonVariant() {
      const types = {
        price: 'outline-primary',
        tax: 'outline-primary',
      }

      types[this.selectedButton] = 'primary'

      return types
    },

    emissaoImage() {
      const options = {
        cri: 'cri',
        cra: 'cra',
        deb: 'debenture',
        nc: 'nota_comercial',
      }

      // eslint-disable-next-line global-require, import/no-unresolved
      if (!this.emissao.tipo_emissao || !options[this.emissao.tipo_emissao.toLowerCase()]){
        return require('@/assets/images/recebíveis.svg')
      }
  
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/images/${options[this.emissao.tipo_emissao.toLowerCase()]}.svg`)
    },
    showingTermos() {
      let start = this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.perPage + 1
      let end = (start === 1) ? this.perPage : (start + this.perPage - 1)

      if (end > (this.totalRows * this.perPage)) end = (this.totalRows * this.perPage)
      if (this.totalRows === 0) start = 0

      return `Mostrando de ${start} a ${end} de ${this.totalItemsTermos} registros`
    },
    showing() {
      let start = this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.perPage + 1
      let end = (start === 1) ? this.perPage : (start + this.perPage - 1)

      if (end > (this.totalRows * this.perPage)) end = (this.totalRows * this.perPage)
      if (this.totalRows === 0) start = 0

      return `Mostrando de ${start} a ${end} de ${this.totalItems} registros`
    },
    checkEvents() {
      if(this.eventos.length > 0) {
        return true
      }
      return false
    }
  },
  watch: {
    perPage: function (newVal) {
      this.changePage();
    },
    statusObrigacoes: function (newVal) {
      this.changePage();
    },
    search: function(newVal) {
      this.changePage();
    },
    perPageTermos: function (newVal) {
      this.changePageTermo();
    },
    searchTermos: function(newVal) {
      this.changePageTermo();
    },
    currentPageTermos: function(newVal) {
      this.currentPageTermos = newVal
    },
    obrigacoesVencimento: function(newVal) {
      this.changePage();
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.max-w-6 {
  max-width: 6rem;
}
.max-w-10 {
  max-width: 10rem;
}

.emissoes-details-button {
  background-color: #3fe4c6 !important;
  border: 1px solid #1eb499 !important;
}

.btn-primary {
  color: #000000 !important;
}

.cs-select .vs__dropdown-toggle {
  border-radius: 0.357rem 0 0 0.357rem;
}

.cs-select .vs--open .vs__dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-icon {
  position: relative;
  display: inline-block;
}

.icon-position {
  position: absolute;
  right: 10px;
  top: 8px;
}

.pus-table th {
  background-color: #eff4f6;
  color: #242a32;
}

.emission-card-border {
  border-radius: 8px;
  border: 1px solid #becad4;
}

.emission-card-bg {
  border-radius: 8px;
  border: 1px solid #becad4;
  background: #f8fafc;
}

.status-obrigacoes {
  min-width: 160px;
  ::placeholder {
    opacity: 1; /* Firefox */
  }
}
</style>
